/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// Material Kit 2 React examples
import IconInfoCard from "examples/Cards/InfoCards/IconInfoCard";
function KeyFeatures() {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="h2" fontWeight="bold">
            Key Features          
          </MKTypography>
        </Grid>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            <IconInfoCard
              title="Fully Automated Service"
              description="The complete robotic barista service delivers a reliably modern coffee experience"
              icon="precision_manufacturing"
            />
          </Grid>
          <Grid item xs={12} md={4} display="flex">
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
            <IconInfoCard
              title="Customizable Drink Flavors"
              description="A menu that caters to the preferences of every coffee aficionado"
              icon="local_cafe"
            />
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <IconInfoCard
              title="Rewards and Loyalty Programs"
              description="Personalized discounts for loyal customers and VIPs"
              icon="emoji_events"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default KeyFeatures;
