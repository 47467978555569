/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { lazy, Suspense } from "react";
// @mui material components
const Card = lazy(() => import("@mui/material/Card"));
const Container = lazy(() => import("@mui/material/Container"));
const Grid = lazy(() => import("@mui/material/Grid"));
// Material Kit 2 React components
const MKBox = lazy(() => import("components/MKBox"));
const MKTypography = lazy(() => import("components/MKTypography"));
const MKButton = lazy(() => import("components/MKButton"));

// Material Kit 2 React examples
const DefaultNavbar = lazy(() => import("examples/Navbars/DefaultNavbar"));

// Robot page sections
const Profile = lazy(() => import("pages/LandingPages/Robot/sections/Profile"));
const Specs = lazy(() => import("pages/LandingPages/Robot/sections/Specs"));
const Concept = lazy(() => import("pages/LandingPages/Robot/sections/Concept"));
const DefaultFooter = lazy(() => import("examples/Footers/DefaultFooter"));
const Menu = lazy(() => import("pages/LandingPages/Robot/sections/Menu"));
import footerRoutes from "footer.routes";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/coffee-cup-with-beans.jpg";

function Robot() {
  return (
    <Suspense>
      <DefaultNavbar
        routes={routes}
        transparent
        light
      />
      <Suspense>
        <MKBox bgColor="white">
          <Suspense><MKBox
            minHeight="25rem"
            width="100%"
            sx={{
              backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                `${linearGradient(
                  rgba(gradients.dark.main, 0.8),
                  rgba(gradients.dark.state, 0.8)
                )}, url(${bgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "grid",
              placeItems: "center",
            }}
          >
            <Container>
              <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
                <MKTypography
                  variant="h2"
                  color="white"
                  mt={-6}
                  mb={1}
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  Meet Our Robot {" "}
                </MKTypography>
              </Grid>
            </Container>
          </MKBox>
          </Suspense>
          <Suspense>
            <Card
              sx={{
                p: 2,
                mx: { xs: 2, lg: 3 },
                mt: -8,
                mb: 4,
                backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
                backdropFilter: "saturate(200%) blur(30px)",
                boxShadow: ({ boxShadows: { xxl } }) => xxl,
              }}
            >
              <Suspense><Profile /></Suspense>
              <Suspense><Concept /></Suspense>
              <Suspense><Menu /></Suspense>
              <Suspense><Specs /></Suspense>
              <Suspense>
                <MKBox component="section" py={2}>
                  <Container>
                    <MKButton variant="contained" color="primary" href="/benefits">
                      Check out my benefits
                    </MKButton>
                  </Container>
                </MKBox>
              </Suspense>
            </Card>
          </Suspense>
          <Suspense>
            <MKBox pt={6} px={1} mt={6}>
              <DefaultFooter content={footerRoutes} />
            </MKBox>
          </Suspense>
        </MKBox>
      </Suspense>
    </Suspense>
  );
}

export default Robot;
