/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-robo-advantage-front.jpg";
import bgBack from "assets/images/rotating-robo-advantage-back.jpg";

function Advantages() {
  const cardAction = {
    route: "/benefits",
    label: "Learn Benefits",
    color: "info"
  };
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                color="dark"
                title={
                  <>
                    Feel the
                    <br />
                    Robo Advantage
                  </>
                }
                action={cardAction}
                description="Experience unparalleled consistency and efficiency in your coffee service with our state-of-the-art robotic barista."
              />
              <RotatingCardBack
                image={bgBack}
                icon="search"
                color="dark"
                title="Discover More"
                action={cardAction}
                description="From carefully sourced coffee beans to expertly brewed cups, our robotic barista delivers a flawless coffee experience every time."
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="check_circle_outline"
                  title="Consistent Quality"
                  description="Robotic baristas ensure consistent quality in every cup of coffee, eliminating variations caused by human error."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="bolt"
                  title="Increased Efficiency"
                  description="They work quickly and efficiently, reducing wait times for customers and increasing overall productivity."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="price_change"
                  title="Save Time & Money"
                  description="While the initial investment may be significant, robotic baristas can reduce long-term labor costs by requiring minimal human supervision."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="update"
                  title="24/7 Operation"
                  description="Robotic baristas can operate around the clock without breaks, providing service whenever needed, even during off-peak hours."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Advantages;
