/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 React pages
import ContactUs from "pages/LandingPages/ContactUs";
import { Helmet } from "react-helmet";

export default function ContactUsPage() {
  return <>
    <Helmet>
      <title>Contact Us</title>
      <meta name="description"
        content="We are actively seeking placements for our robo baristas. If you have the ideal location in mind, we can help make it a reality!"></meta>
    </Helmet>
    <ContactUs />
  </>;
}
