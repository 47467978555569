/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 React pages
import Presentation from "pages/Presentation";
import { Helmet } from "react-helmet";

export default function PresentationPage() {
  return <>
    <Helmet>
      <title>Robo Barista Cafe | Coffee Robot | Robot Barista</title>
      <meta name="description" content="Elevate your coffee experience with our state-of-the-art Robot Barista. Precision brewing, customizable options, and flawless execution ensure the perfect cup every time. Join us in the future of coffee craftsmanship!"></meta>
    </Helmet>
    <Presentation />
  </>;
}
