/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

// Material Kit 2 React components
import MKBox from "components/MKBox";
// import MKInput from "components/MKInput";
// import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";


function ContactUs() {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={routes}
        />
      </MKBox>
      <Grid container spacing={3} alignItems="center">
        <Grid
          item
          xs={12}
          sm={10}
          md={10}
          lg={10}
          xl={10}
          ml={"auto"}
          mr={"auto"}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Contact us
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body1" color="text" style={{textAlign: "center"}}>
                We are actively seeking placements for our robo baristas.
              </MKTypography>
              <MKTypography variant="body2" color="text" mb={3} style={{textAlign: "center"}}>
                If you have the ideal location in mind, we can help make it a reality!
              </MKTypography>
              {!loaded && <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </div>}
              <iframe aria-label='Contact Us'
                id="myFrame"
                onLoad={() => {
                  console.log("set loaded");
                  setLoaded(true);
                }}
                scrolling="no"
                frameBorder="0"
                style={{
                  width: "100%",
                  height: "1200px",
                }}
                src='https://forms.zohopublic.com/robobaristacafe/form/Inquire/formperma/Y7dee9haE-LWhNmelMY2mcftf9c1uRtRDcaifsrJsZY'></iframe>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
