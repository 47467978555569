/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 React pages
import Benefits from "pages/LandingPages/Benefits";
import { Helmet } from "react-helmet";

export default function AboutUsPage() {
  return <>
  <Helmet>
      <title>Benefits</title>
      <meta name="description"
        content="Robot baristas are the future of coffee service, combining efficiency, consistency, and innovation to revolutionize the way we experience our daily brew."></meta>
    </Helmet>
  <Benefits />
  </>;
}
