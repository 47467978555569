/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 React pages
import AboutUs from "pages/LandingPages/AboutUs";
import { Helmet } from "react-helmet";

export default function AboutUsPage() {
  return <>
<Helmet>
      <title>About Us</title>
      <meta name="description"
        content="Welcome to Robo Barista Cafe, where cutting-edge technology meets the art of coffee making."></meta>
    </Helmet>  
  <AboutUs />
  </>;
}
