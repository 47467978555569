/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 React pages
import Robot from "pages/LandingPages/Robot";
import { Helmet } from "react-helmet";

export default function RobotPage() {
  return <>
    <Helmet>
      <title>The Robot</title>
      <meta name="description"
        content="Your friendly neighborhood robot barista! Serving up perfect coffee, every time. Precision brewing, sustainable sips, and a dash of personality."></meta>
    </Helmet>
    <Robot />
  </>;
}
