/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/Robot-Latte-Art.jpeg";

function AboutUs() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        transparent
        light
      />
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Where Robotics Meet Rich Flavors
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              Experience the future of coffee today—get your robo barista now and elevate your coffee game to a whole new level!
            </MKTypography>
              <MKButton href="/contact-us" component="a" color="default" sx={{marginBottom: "10px", color: ({ palette: { dark } }) => dark.main }}>
                Work with us
              </MKButton>
              <MKButton href="/benefits" component="a" color="primary" sx={{marginBottom: "10px" }}>
                Robot Benefits
              </MKButton>
              <MKButton href="/robot" component="a" color="info" >
                Meet Robot
              </MKButton>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container>
          <Grid container
            item
            xs={12}
            lg={8}
            sx={{ mx: "auto", textAlign: "center" }}
            >
            <MKTypography variant="body2" color="default" >
              Welcome to Robo Barista Cafe, where cutting-edge technology meets the art of coffee making. 
              <br />
              <br />
              We are revolutionizing the coffee experience by combining precision engineering with a passion for exceptional brews. Our mission is to provide consistently perfect cups of coffee, tailored to individual tastes, through our state-of-the-art robotic baristas.
              <br />
              <br />
              Founded by a team of coffee aficionados and tech innovators, Robo Barista Cafe is dedicated to enhancing your coffee moments with convenience, efficiency, and quality. Our robotic baristas are designed to operate seamlessly in various environments, from bustling airports and train stations to corporate offices and healthcare facilities. They ensure that every cup is brewed to perfection, offering a wide range of customizable options to satisfy diverse preferences.
              <br />
              <br />
              We believe in pushing the boundaries of what&apos;s possible in coffee service. Our robots are equipped with many advanced features ensuring a hygienic and efficient service that can adapt to your needs around the clock.
              <br />
              <br />
              Join us on our journey to transform the coffee experience, one perfect cup at a time. Discover the future of coffee with Robo Barista Cafe—where innovation brews excellence.
              <br />
              <br />
              <a href="/contact-us">
              <MKButton color="info" >
                Work with us
              </MKButton>
            </a>
            </MKTypography>
          </Grid>
        </Container>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AboutUs;
