/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useRef } from "react";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Information from "pages/LandingPages/Benefits/sections/Information";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/Robot-Pouring-Coffee.jpg";
import MKButton from "components/MKButton";
import { getCookieConsentValue } from "react-cookie-consent";


function Benefits() {
  const refCard = useRef(null);

  return (
    <>
      {getCookieConsentValue() &&
        <a href="/contact-us"><Fab color="primary" variant="extended" aria-label="Inquire Now" sx={{ position: "fixed", bottom: "5%", right: "2%" }}>Inquire Now</Fab></a>}
      <DefaultNavbar
        routes={routes}
        transparent
        light
      />
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Why Choose Robo Barista?
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              Disrupting the Coffee Industry with Precision and Efficiency, Redefining the Art of Brewing Perfection
            </MKTypography>
            <MKButton sx={{ marginBottom: "10px" }} onClick={() => { refCard.current?.scrollIntoView({ behavior: 'smooth' }) }} size="large">
              <Icon fontSize="medium">keyboard_double_arrow_down</Icon>Find Out Now<Icon fontSize="medium">keyboard_double_arrow_down</Icon>
            </MKButton>
            <MKButton component="a" href="/robot" size="large" color="info">
              <Icon fontSize="medium">smart_toy</Icon>&nbsp;Meet Robot
            </MKButton>
          </Grid>
        </Container>
      </MKBox>

      <Card
        ref={refCard}
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
      </Card>


      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>

    </>
  );
}

export default Benefits;
