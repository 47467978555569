// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/shapes/precision_manufacturing.svg";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Robo Barista Cafe",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/people/Robo-Barista-Cafe/61559771978627/",
      name: "Facebook Page"
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/RoboBaristaCafe",
      name: "Twitter Page"
    },
  ],
  menus: [
    {
      name: "help & support",
      items: [
        { name: "about us", href: "/about-us" },
        { name: "contact us", href: "/contact-us" },
      ],
    },
    {
      name: "legal",
      items: [
        { name: "terms & conditions", href: "/terms" },
        { name: "privacy policy", href: "/privacy" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date}&nbsp;Robo Barista Cafe.
    </MKTypography>
  ),
};
