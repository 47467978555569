/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import robotHoldImage from "assets/images/Robot-Hold-Coffee.jpg";

function Information() {
  return (
    <MKBox id="benefits-section" component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="paid"
                    title="Labor Cost"
                    description="Labor is the most significant expense for any business. A robot barista, eliminates the need for human wages, benefits, and related expenses. This can result in substantial savings over time, especially in regions where labor costs are high."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="zoom_in_map"
                    title="Space Optimization"
                    description="Robot baristas typically require less space than traditional coffee shop setups, as they are often compact and designed for efficiency. This can lead to savings in terms of rent or lease expenses for the coffee shop premises."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="auto_awesome"
                    title="Self Marketing"
                    description="Watching a robot barista in action can be entertaining and even mesmerizing for customers. The novelty of a robot barista can generate buzz on social media or even encourage sharing by word of mouth."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="clean_hands"
                    title="Hygiene and Sanitation"
                    description="Robot baristas eliminate the risk of human error or contamination during the coffee-making process, ensuring a high level of hygiene and sanitation. This can be particularly important in a post-pandemic world where customers prioritize cleanliness and safety."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={robotHoldImage}
              title="Join the Robo Revolution!"
              description="Robot baristas are the future of coffee service, combining efficiency, consistency, and innovation to revolutionize the way we experience our daily brew."
              action={{
                type: "internal",
                route: "/contact-us",
                color: "info",
                label: "Inquire Now",
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <MKBox mb={5}>
              <DefaultInfoCard
                icon="electric_bolt"
                title="High Efficiency"
                description="The robo barista can craft over 600 cups of specialty coffee in a single day, ensuring your customers get their favorite drinks faster than ever before."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={3}>
            <MKBox mb={5}>
              <DefaultInfoCard
                icon="update"
                title="24/7 Operation"
                description="While the average barista works for about 6-12 months, the robotic barista operates continuously without ever getting tired or sick. This consistent reliability effectively addresses the problem of staff turnover."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={3}>
            <MKBox mb={5}>
              <DefaultInfoCard
                icon="construction"
                title="Low Maintenance"
                description="Maintaining the robo barista requires no special skills. Simply perform a quick daily check-up to ensure it is ready to serve customers. One person can easily manage up to eight robo baristas daily."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={3}>
            <MKBox mb={5}>
              <DefaultInfoCard
                icon="insights"
                title="Actionable Insights"
                description="Our robo baristas comes collect statistics on a daily basis. This can help you identify the most popular drinks that your robot is serving. Using this data, you could adjust your maintenance and refilling strategies."
              />
            </MKBox>
          </Grid>
        </Grid>
        <MKButton component="a" href="/robot" size="large" color="info">
              <Icon fontSize="medium">smart_toy</Icon>&nbsp;Meet Robot
            </MKButton>
      </Container>
    </MKBox>
  );
}

export default Information;
