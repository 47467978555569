/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import location1 from "assets/images/location/hospital.jpg";
import location2 from "assets/images/location/univerisity-college-campus.jpg";
import location3 from "assets/images/location/mall.jpg";
import location4 from "assets/images/location/airport.jpg";

function Placement() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Options for Deploying Your Coffee Robots
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              The right location can do wonders to your coffee robot deployment.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={location1}
                name="Hospital"
                position={{ color: "info"}}
                description="Hospitals have an influx of visitors whether it's patients, their family members, doctors or other staff members."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={location2}
                name="University/College Campus"
                position={{ color: "info" }}
                description="Students and professors are always buzzing around campus and in need of a caffeine boost to keep them going from class to class."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={location3}
                name="Retail Spaces"
                position={{ color: "info" }}
                description="Eager shoppers at malls and shopping centers provide the perfect setup with a never ending supply of new customers."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={location4}
                name="Transportation Hubs"
                position={{ color: "info" }}
                description="Exhausted travellers at airports/train stations are always looking for something to keep them going and a cup of coffee is exactly what they need."
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Placement;
